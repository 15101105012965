<template>
    <iframe
        src="https://view.xdocin.com/xdoc?_xdoc=https%3A%2F%2Fhuhuiyun.oss-cn-shanghai.aliyuncs.com%2Fstatic%2F%25E4%25B8%259A%25E4%25B8%25BB%25E5%25A4%25A7%25E4%25BC%259A%25E5%2585%25AC%25E5%2591%258A%25E6%25A8%25A1%25E7%2589%2588%25E4%25B8%2580.docx&_toolbar=false&_printable=false&_saveable=false&_pdf=false&_copyable=false"
        width="100%"
        :height="clientHeight"
        :style="{
            height: clientHeight - 122 - 80,
        }"
        frameborder="1">
    </iframe>
</template>

<script>
export default {
	data() {
		return {
			clientHeight: 500,
		}
	},
    mounted() {
		this.clientHeight = `${document.documentElement.clientHeight}`
		window.onresize = function temp() {
			this.clientHeight = `${document.documentElement.clientHeight}`
		}
	},
}
</script>
